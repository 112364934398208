<template>
  <list-template
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onChangePage="changeCurrentPage"
      @onSearch="onSearch"
      @onHandle="onHandle"
      :loading="loading"
  >
  </list-template>
</template>

<script>
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data(){
    return{
      loading:true,
      total:0,
      tableData:[],
      tableConfig:[
          {
            prop:"receiving_name",
            label:"收信人",
          },
          {
            prop:"receiving_type",
            label:"收信人角色",
          },
          {
            prop:"maker_name",
            label:"发信人",
          },
          {
            prop:"maker_type",
            label:"发信人角色",
          },
          {
            prop:"maker_time",
            label:"发信时间",
          },
          {
            prop:"receiving_time",
            label:"收信时间",
          },
          {
            prop:"content",
            label:"留言内容",
          },
          {
            label:"操作",
            handle:true,
            render(){
              return ["查看"]
            }
          },
      ],
      search:null,
      searchConfig:[
        {
          tag:"datePicker",
          prop: "times",
          property:{
            type:"daterange",
            startPlaceholder:"发信时间范围",
            endPlaceholder:"发信时间范围",
            size:"small",
            style:"margin-right:20rem;",
            valueFormat:"yyyy-MM-dd"
          }
        },
        {
          prop:"maker_name",
          placeholder:"搜索发信人"
        },
        {
          prop:"receiving_name",
          placeholder:"搜索收信人"
        }
      ]
    }
  },
  computed:{
    ...mapState(["page"])
  },
  methods:{
    changeCurrentPage(cur){
      this.$store.commit('setPage', cur)
      this.getData()
    },
    getData(){
      let search = Object.assign({},this.search) || {};
      let {times} = search;
      if (times){
        search.start_time = times[0];
        search.end_time = times[1];
        delete search.times;
      }
      let params = {
        page:this.page,
        ...search
      }
      this.loading = true;
      this.$_axios2.get("/api/message/index/list",{
        logic:1,
        params
      }).then(res=>{
        let {list,page} = res.data;
        this.tableData = list;
        this.total = page.total;
      }).finally(()=>this.loading = false);
    },
    onSearch(val){
      this.search = val;
      this.$store.commit("setPage",1);
      this.getData();
    },
    onHandle(row,text,index){
      this.$router.push({path:"./show",query:{id:row.id}})
    }
  },
  mounted() {
    this.$store.commit('setPage', 1);
    this.getData();
  },
  components:{
    ListTemplate
  }
}
</script>

<style scoped>

</style>
